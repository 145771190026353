<template>
  <transition name="fadePage" mode="out-in">
    <div class="myAssets BabAssets">
      <Top-Nav></Top-Nav>
      <div class="common_title">{{$t('menu.wdzc')}}</div>
      <div class="lab_container">
        <div class="sec_desc">
          <div class="l_sec"><img src="../../../../../public/images/BAB/icon-suanli.png" alt=""></div>
          <div class="r_sec">
            <div class="num_sec">{{(userMintInfo._pow>0?(4800*1.5)-formatAmountMethod(userMintInfo._pow):0) | numFormat2}}</div>
            <div class="nam_sec"><span>{{$t('menu.residualComPower')}}</span></div>
          </div>
        </div>
        <div class="sec_desc">
          <div class="l_sec"><img src="../../../../../public/images/BAB/icon-suanli.png" alt=""></div>
          <div class="r_sec">
            <div class="num_sec">{{formatAmountMethod(userMintInfo._pow)}}</div>
            <div class="nam_sec">
              <router-link :to="{name:'BiAnBaoComputing',path:'/biAnBaoComputing'}">
                <span>{{$t('menu.yccsl')}}</span><img src="../../../../../public/images/right.png" alt="">
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="change_container">
        <!-- STAR -->
        <div class="swiper-slide-my">
          <div class="box">
            <div class="pic_b"><img :src="star.img" alt=""></div>
            <div class="center_cont">
              <div class="cout_num">{{star.total_num}}</div>
              <div class="cout_name">
                <router-link :to="{name:'BiAnBaoAssetDetails',path:'/BiAnBaoAssetDetails',query: {type:star.tpye}}">
                  <span>{{$t('menu.'+star.total_name)}}</span><img src="../../../../../public/images/right.png" alt="">
                </router-link>
              </div>
            </div>
            <div class="bottom_cont">
              <div>
                <div class="num_tq">{{formatAmountMethod(userRefInfo._waitReceiveStar)}}</div>
                <!-- <div class="num_tq_name">{{$t('menu.miningBeExtracted')}}</div> -->
                <div class="num_tq_name">待提取</div>
                <div class="num_tq_btn"><button :disabled="btn3" @click="refWithdrawClick" :class="{disabled_btn:btn3}">{{$t('menu.extract')}}</button></div>
                
              </div>
              <!-- <div>
                <div class="num_tq">{{formatAmountMethod(userNodeInfo._waitReceiveStar)}}</div>
                <div class="num_tq_name">{{$t('menu.nodeBeExtracted')}}</div>
                <div class="num_tq_btn"><button :disabled="btn2" @click="withdrawNodeRewardClick" :class="{disabled_btn:btn2}">{{$t('menu.extract')}}</button></div>
              </div> -->
              <div>
                <div class="num_tq">{{formatAmountMethod(userRefInfo._alreadyRecieveStar)}}</div>
                <div class="num_tq_name">{{$t('menu.extracted')}}</div>
                <!-- <div class="num_tq_btn"><a href="javascript:;" @click="approveStar" :class="{disabled_a:isDisabl_a}">{{$t('menu.grantAuthorization')}}</a></div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- BAB -->
        <div class="swiper-slide-my">
          <div class="box">
            <div class="pic_b"><img :src="BNB.img" alt=""></div>
            <div class="center_cont">
              <div class="cout_num">{{BNB.total_num}}</div>
              <div class="cout_name">
                <router-link :to="{name:'BiAnBaoAssetDetails',path:'/BiAnBaoAssetDetails',query: {type:BNB.tpye}}">
                  <span>{{$t('menu.'+BNB.total_name)}}</span><img src="../../../../../public/images/right.png" alt="">
                </router-link>
              </div>
            </div>
            <div class="bottom_cont">
              <div>
                <div class="num_tq">{{formatAmountMethod(userMintInfo._waitReceiveBnb)}}</div>
                <div class="num_tq_name">{{$t('menu.toBeExtracted')}}</div>
                <div class="num_tq_btn"><button :disabled="btn1" @click="withdrawSwapRewardClick" :class="{disabled_btn:btn1}">{{$t('menu.extract')}}</button></div>
              </div>
              <div>
                <div class="num_tq">{{formatAmountMethod(userMintInfo._alreadyRecieveBnb)}}</div>
                <div class="num_tq_name">{{$t('menu.extracted')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog :title="dialogTitle" :visible.sync="isShowDialog" :close-on-click-modal="false" top="30vh" width="400px">
        <el-form :inline="true" class="deleteForm">
          <el-switch v-model="dialogWithdrawType" active-text="具体金额" inactive-text="提取全部">
          </el-switch>
          <el-form-item label="提取具体金额" v-if="dialogWithdrawType">
            <!-- <el-input v-model="beginWorker" autocomplete="off" placeholder="请输入登记者的名字"></el-input> -->
            <el-input-number v-model="dialogWithdrawNum" :precision="5" :min="0" :step="0.1" label="描述文字"></el-input-number>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="isShowDialog = false">取 消</el-button>
          <el-button size="small" type="primary" @click="doWithdraw">确 定</el-button>
        </div>
      </el-dialog>

    </div>
  </transition>
</template>
<script>
import '../../../../style/myAssets.css';
import '../styles/BiAnBao.css';
import TopNav from '../common/BabTop.vue';
// import Swiper from 'swiper'; // 注意引入的是Swiper

const bnbTreasurePool = require('../../../../utils/bnbTreasurePool')

export default {
  name: 'BiAnBaoAssets',
  components: { TopNav },
  data() {
    return {
      btn1: false,
      btn2: false,
      btn3: false,
      btn4: false,
      formatAmountMethod: bnbTreasurePool.default.amountConversion,
      // isDisabl_a:false,//禁用a标签
      residualComPower: 0,//剩余算力
      computingPower: 0,//已产出算力
      swiperOption: {
        pagination: {
          paginationClickable: true,
          slidesPerView: 'auto',
          spaceBetween: 30,
          centeredSlides: true,
          effect: 'coverflow'
        }
      },
      star: {
        img: require("../../../../../public/images/BAB/img-Star.png"),
        total_num: "0",
        total_name: "totalAssets_star",
        tpye: 1,
      },
      zeed: {
        img: require("../../../../../public/images/icon-Zeed-zc.png"),
        total_num: "0",
        total_name: "totalAssets_zeed",
        tpye: 2,
      },
      yeed: {
        img: require("../../../../../public/images/icon-Yeed-zc.png"),
        total_num: "0",
        total_name: "totalAssets_yeed",
        tpye: 3,
      },
      BNB: {
        img: require("../../../../../public/images/BAB/iimg-BNB.png"),
        total_num: "0",
        total_name: "totalAssets_BNB",
        tpye: 4,
      },
      //推荐奖励信息
      userRefInfo: {
        _refCount: 0, // 一级推荐人数
        _alreadyRecieveStar: 0, // 累计已领取Star数量
        _alreadyRecieveStarToUsdt: 0, // 折合XXX USDT
        _waitReceiveStar: 0, // 累计未领取Star数量
        _waitReceiveStarToUsdt: 0 // 折合xx USDT
      },
      // 用户挖矿数据：（累计已领取start数量，折合XXX USDT,累计未领取star数量，折合xx USDT）
      userMintInfo: {
        _pow: 0, //用户算力
        _amount: 0, //申购金额
        _alreadyRecieveBnb: 0,
        _alreadyRecieveBnbToUsdt: 0,
        _waitReceiveBnb: 0,
        _waitReceiveBnbToUsdt: 0
      },
      // 节点数据：（累计已领取start数量，折合XXX USDT,累计未领取star数量，折合xx USDT）
      userNodeInfo: {
        _isNode: 0,//是否是节点
        _alreadyRecieveStar: 0,
        _alreadyRecieveStarToUsdt: 0,
        _waitReceiveStar: 0,
        _waitReceiveStarToUsdt: 0,
      },
      isShowDialog: false,
      dialogTitle: '提现',// 弹窗标题
      dialogType: '',// 弹窗类型
      dialogWithdrawType: false, // 提现类型，false:提取全部，true:具体金额
      dialogWithdrawNum: 0,// 提现具体金额

    }
  },
  created: function () {
    // 资产查询
    let that = this
    // 获取STAR余额
    bnbTreasurePool.default.getStarBalance().then(res => {
      that.star.total_num = res
    })
    // // 获取ZEED余额
    // bnbTreasurePool.default.getSeedTokenBalance().then(res=>{
    //   that.zeed.total_num = res
    // })
    // 获取BNB余额
    bnbTreasurePool.default.getBnbBalance().then(res => {
      that.BNB.total_num = res
    })

    //推荐奖励信息
    bnbTreasurePool.default.userRefInfo().then(res => {
      that.userRefInfo = res
    })

    // 用户挖矿数据
    bnbTreasurePool.default.userMintInfo().then(res => {
      that.userMintInfo = res
    })
    // // 节点数据
    // bnbTreasurePool.default.userNodeInfo().then(res=>{
    //   that.userNodeInfo = res
    // })
  },
  mounted() {

  },
  methods: {
    extract() {
      // this.$toast("提取成功");
    },
    // 展示提现弹窗
    showDialog(title, type) {
      console.log(title, type)
      this.dialogWithdrawType = false
      this.dialogWithdrawNum = 0
      this.dialogTitle = title
      this.dialogType = type
      this.isShowDialog = true

    },
    // 执行提现
    doWithdraw() {
      console.log(this.dialogTitle, this.dialogWithdrawNum)
      switch (this.dialogType) {
        case 'withdrawSwapReward':
          this.withdrawSwapReward()
          break;
        case 'withdrawNodeReward':
          this.withdrawNodeReward()
          break;
        case 'refWithdraw':
          this.refWithdraw()
          break;
        default:
          this.$toast('没有对应的方法');
          break;
      }
      this.isShowDialog = false
    },
    withdrawSwapRewardClick() {
      this.showDialog('算力奖励提现', 'withdrawSwapReward')
    },
    withdrawNodeRewardClick() {
      this.showDialog('节点奖励提现', 'withdrawNodeReward')
    },
    refWithdrawClick() {
      this.showDialog('推荐奖励提现', 'refWithdraw')
    },

    // 价格都传0
    /**
     * 算力奖励提现
     */
    withdrawSwapReward() {
      let that = this
      that.btn1 = true

      // let _amount =bnbTreasurePool.default.amountConversion(this.userMintInfo._waitReceiveStar)
      let _amount = this.dialogWithdrawNum
      console.log('算力奖励提现数量', _amount);
      bnbTreasurePool.default.withdrawSwapReward(_amount.toString()).then(res => {
        that.btn1 = false;
        this.$toast(this.$t('menu.extractionSucceeded'));
        console.log('算力奖励提现res', res);
      })
    },
    //节点奖励提现
    withdrawNodeReward() {
      let that = this
      that.btn2 = true

      // let _amount =bnbTreasurePool.default.amountConversion(this.userNodeInfo._waitReceiveStar)
      let _amount = this.dialogWithdrawNum
      console.log('节点奖励提现数量', _amount);
      bnbTreasurePool.default.withdrawNodeReward(_amount.toString()).then(res => {
        console.log('节点奖励提现res', res);
        this.$toast(this.$t('menu.extractionSucceeded'));
        that.btn2 = false
      })
    },
    //提现推荐奖励
    refWithdraw() {
      let that = this
      that.btn3 = true;
      // let _amount =bnbTreasurePool.default.amountConversion(this.userRefInfo._waitReceiveSeed)
      let _amount = this.dialogWithdrawNum
      console.log('提现推荐奖励数量', _amount);
      bnbTreasurePool.default.refWithdraw(_amount.toString()).then(res => {
        console.log('提现推荐奖励res', res);
        // that.btn3 = false;
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 授权STAR
    // approveStar(){
    //   let that = this
    //   that.isDisabl_a = true
    //   bnbTreasurePool.default.approveStar().then(res => {
    //     console.log(res)
    //     that.isDisabl_a = false
    //   })
    // }

  }
}
</script>
<style scoped>
/* @media screen and (max-width: 500px) {
   .el-dialog__wrapper .el-dialog {
      width: 300px !important;
      .el-dialog__body{
        padding: 10px 20px!important;
        .el-form-item__label{
          width: 68px!important;
        }
        .el-select,.el-input{
          width: 180px!important;
        }
      }
    }
} */
.BabAssets .lab_container .sec_desc {
  background: url(../../../../../public/images/BAB/box4.png) no-repeat;
  background-size: 100% 100%;
}
.swiper-slide-my {
  background: url(../../../../../public/images/BAB/box5.png) no-repeat;
  background-size: 100% 100%;
}
.BabAssets .change_container .bottom_cont .num_tq_btn button {
  background-color: #fcc413;
  box-shadow: 0px 6px 12px #161102;
  color: #2b2e35;
}
</style>